<template>
  <div>
    <div v-if="step === 1" class="d-flex justify-center">
      <Spinner />
    </div>
    <template v-if="step === 2">
      <div style="text-align: left;">
        <h4 style="text-align: center; margin-top: 30px;">
          {{ $t('confirmPhoneNumber') }}
        </h4>
        <div style="text-align: center; padding: 0 20px;">
          <BaseInput
            v-model="phone"
            label=""
            :placeholder="$t('enterPhone')"
            :invalid="$v.phone.$error"
            :error="$t('errorEmptyField')"
          />
          <p v-show="!!responseText" class="mb-5" style="font-size: 16px; text-align: center; margin-top: -15px;">
            {{ responseText }}
          </p>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <BaseButton oval :loading="loading" @click="confirmPhone">{{ $t('buttonConfirm') }}</BaseButton>
      </div>
    </template>
    <template v-if="step === 3">
      <div style="text-align: left;">
        <h4 style="text-align: center; margin-top: 30px;">
          {{ $t('enterCodeFromTelegram') }}
        </h4>
        <div style="text-align: center; padding: 0 20px;">
          <BaseInput
            v-if="!successLogin"
            v-model="code"
            label=""
            :placeholder="$t('enterCode')"
            :invalid="$v.code.$error"
            :error="$t('errorEmptyField')"
          />
          <p v-show="!!responseText" style="font-size: 16px; text-align: center; margin-top: 10px; margin-bottom: 10px;">
            {{ responseText }}
          </p>
        </div>
      </div>
      <div style="display: flex; justify-content: center;">
        <BaseButton v-show="!successLogin" oval :progress="loading" style="margin-right: 10px;" @click="confirmCode">{{ $t('buttonConfirm') }}</BaseButton>
      </div>
    </template>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/_BaseButton'
import BaseInput from '@/components/atoms/_BaseInput'
import Spinner from '@/components/atoms/Spinner'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { dataForCreateBridgeTGRoom } from '@/helpers'
import { msgTypes, eventTypes, chatNamesForBridge, selfMembershipTypes } from '@/helpers/variables'

export default {
  name: 'bridge-telegram',
  components: {
    BaseButton,
    BaseInput,
    Spinner
  },
  data () {
    return {
      step: 1,
      loading: false,
      roomId: null,
      responseText: '',
      successLogin: false,
      TGConnectingRoom: null,
      code: '',
      phone: ''
    }
  },
  validations: {
    code: {
      required
    },
    phone: {
      required
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/profile'
    })
  },
  created () {
    this.beforeOpen()
    this.activate()
  },
  methods: {
    ...mapActions({
      updateMatrixCredentials: 'matrix/updateMatrixCredentials',
      getMatrixCredentials: 'matrix/getMatrixCredentials'
    }),
    beforeOpen () {
      this.step = 1
      this.loading = false
      this.roomId = null
      this.TGConnectingRoom = null
      this.responseText = ''
      this.successLogin = false
    },
    async createRoom () {
      const result = await window.matrixSDK.createRoom(dataForCreateBridgeTGRoom)
      this.roomId = result.room_id
      return result
    },
    async sendMessage (msgbody) {
      const content = {
        body: msgbody,
        msgtype: msgTypes.text
      }
      await window.matrixSDK.sendEvent(this.roomId, eventTypes.message, content, '')
    },
    async activate () {
      try {
        this.loading = true
        const rooms = window.matrixSDK.getRooms()
        const TGRoom = rooms.find(e => e.name === chatNamesForBridge.telegram && e.selfMembership === selfMembershipTypes.join)
        if (TGRoom) {
          this.TGConnectingRoom = TGRoom
          this.roomId = TGRoom.roomId
        } else {
          await this.createRoom()
          this.TGConnectingRoom = window.matrixSDK.getRoom(this.roomId)
        }
        await this.sendMessage('login')
      } finally {
        this.loading = false
      }

      this.step = 2
      this.loading = false
      this.phone = this.user.phone

      this.TGConnectingRoom.on('Room.timeline', async (event) => {
        console.log('Room.timeline----->', event.event.content)
        this.successLogin = event.event.content.body.includes('Successfully logged in as')

        const isInvalidPhoneCode = event.event.content.body.includes('Invalid phone code.')
        const isLoggedIn = event.event.content.body.includes('You are already logged in')
        if (isInvalidPhoneCode) {
          this.responseText = event.event.content.body
        }
        if (isLoggedIn) {
          this.responseText = event.event.content.body
        }

        if (this.successLogin) {
          await this.updateMatrixCredentials({ is_bridge_telegram: true })
          await this.getMatrixCredentials()
          this.$emit('change-step', 4)
        }
      })
    },
    async confirmPhone () {
      this.$v.$touch()
      if (this.loading || this.$v.phone.$invalid) return
      try {
        this.loading = true
        await this.sendMessage(this.phone)
        this.step = 3
      } finally {
        this.loading = false
      }
    },
    async confirmCode () {
      this.$v.$touch()
      if (this.loading || this.$v.code.$invalid) return
      this.loading = true
      try {
        await this.sendMessage(this.code)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
