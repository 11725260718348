<template>
  <div style="text-align: left;">
    <div style="text-align: center;">
      <img v-show="showQR" :src="qrPath" alt="" >
      <p v-show="!!responseText" style="font-size: 16px; text-align: center; margin-top: 10px; margin-bottom: 10px;">
        {{ responseText }}
      </p>
      <div style="display: flex; justify-content: center;">
        <BaseButton v-show="showBtnRestart" oval @click="activate()">{{ $t('buttonRestart') }}</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/_BaseButton'
import { mapActions } from 'vuex'
import { dataForCreateBridgeWARoom } from '@/helpers'
import { chatNamesForBridge, eventTypes, msgTypes, selfMembershipTypes } from '@/helpers/variables'

export default {
  name: 'bridge-whatsapp',
  components: {
    BaseButton
  },
  data () {
    return {
      step: 1,
      loading: false,
      roomId: null,
      qrPath: null,
      showQR: false,
      responseText: '',
      WAConnectingRoom: null,
      showBtnRestart: false
    }
  },
  created () {
    this.beforeOpen()
    this.activate()
  },
  methods: {
    ...mapActions({
      updateMatrixCredentials: 'matrix/updateMatrixCredentials',
      getMatrixCredentials: 'matrix/getMatrixCredentials'
    }),
    beforeOpen () {
      this.step = 1
      this.loading = false
      this.roomId = null
      this.qrPath = null
      this.WAConnectingRoom = null
      this.showQR = false
      this.responseText = ''
      this.showBtnRestart = false
    },
    async createRoom () {
      const result = await window.matrixSDK.createRoom(dataForCreateBridgeWARoom)
      this.roomId = result.room_id
      return result
    },
    createFileUrl (url) {
      return window.matrixSDK.mxcUrlToHttp(url)
    },
    async sendMessage (msgbody) {
      const content = {
        body: msgbody,
        msgtype: msgTypes.text
      }
      await window.matrixSDK.sendEvent(this.roomId, eventTypes.message, content, '')
    },
    async activate () {
      this.loading = true
      this.responseText = ''
      this.showBtnRestart = false

      const rooms = window.matrixSDK.getRooms()
      const WARoom = rooms.find(e => e.name === chatNamesForBridge.whatsapp && e.selfMembership === selfMembershipTypes.join)
      if (WARoom) {
        this.WAConnectingRoom = WARoom
        this.roomId = WARoom.roomId
      } else {
        await this.createRoom()
        this.WAConnectingRoom = window.matrixSDK.getRoom(this.roomId)
      }

      this.WAConnectingRoom = window.matrixSDK.getRoom(this.roomId)
      this.WAConnectingRoom.on('Room.timeline', async (event) => {
        if (event.event.content.msgtype === msgTypes.image) {
          this.qrPath = this.createFileUrl(event.event.content.url)
          this.showQR = true
        }
        if (event.event.content.msgtype === msgTypes.notice) {
          this.showQR = false
          console.log(event.event.content.msgtype, event.event.content.body)
          const { content: { body } } = event.event
          const isSuccessLogin = body.includes('Successfully logged in as')
          const isQrTimeOut = body.includes('QR code timed out')
          if (isSuccessLogin) {
            this.responseText = event.event.content.body
            await this.updateMatrixCredentials({ is_bridge_whatsapp: isSuccessLogin })
            await this.getMatrixCredentials()
            this.$emit('change-step', 4)
          } else if (isQrTimeOut) {
            this.showBtnRestart = true
            this.responseText = event.event.content.body
          }
        }
      })

      await this.sendMessage('login')
      this.step = 2
      this.loading = false
    }
  }
}
</script>
