<template>
  <div class="stepper">
    <div class="stepper-wrap">
      <ul class="stepper-list" :style="{'--cont-steps': countSteps}">
        <li class="stepper-item"
            :class="{
              'active': i === activeStep,
              'completed': activeStep > i
             }"
            v-for="i in countSteps" :key="i">
          <span>
            {{ i }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeStep: {
      type: Number,
      default: 1
    },
    countSteps: {
      type: Number,
      default: 1
    }
  }
}
</script>
<style lang="scss">
.stepper{
  &-list{
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    margin-right: calc(-100% / var(--cont-steps));
    @include respondTo(580px){
      margin-right: calc(-100% / var(--cont-steps) + 22px);
    }
  }
  &-item{
    position: relative;
    width: calc(100% / var(--cont-steps));
    z-index: 2;
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      background: $silver-1;
      height: 1px;
      z-index: 1;
    }
    &:last-child{
      &::after{
        display: none;
      }
    }
    span{
      position: relative;
      z-index: 3;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #dddcdc;
      color: $black;
      font-weight: 600;
      font-size: 14px;
      @include respondTo(580px){
        width: 25px;
        height: 25px;
        font-size: 12px;
      }
    }
    &.active{
      span{
        background-color: $green;
        color: $white;
      }
    }
    &.completed{
      span{
        font-size: 0;
        background-color: $green;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-image: url('../../assets/img/svg/check-white-ico.svg');
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          z-index: 3;
        }
      }
      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        background: $green;
        height: 1px;
        z-index: 2;
      }
    }
    &__number{

    }
  }
}

</style>
