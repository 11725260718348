<template>
  <div class="connect-flow">
    <div class="bridge-title">{{ $t('bridgeToWhatsApp') }}</div>
    <BridgeWhatsApp @change-step="$emit('change-step', $event)" />
    <div class="connect-flow__text">
      <p v-html="$t('WAFlowText1').replace(':image', `<img src='${icoSetting}' width='20' alt=''/>`)"></p>
      <p>
        {{ $t('WAFlowText2') }}  <img src="@/assets/img/laptop_ico.png" width="25" alt="">
      </p>
      <p v-html="$t('WAFlowText3')"></p>
    </div>
  </div>
</template>
<script>
import BridgeWhatsApp from '../providers/BridgeWhatsApp'
export default {
  name: 'WhatsappFlow',
  components: {
    BridgeWhatsApp
  },
  computed: {
    icoSetting () {
      return require('@/assets/img/ios_cog_ico.png')
    }
  }
}
</script>
