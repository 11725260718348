<template>
  <div class="login_step">
    <LoginByQRCode :visibleOnlyQR="true" @on-success-auth="$emit('change-step', 2)" />
    <p>
      {{ $t('loginInHolaText1') }}
    </p>
    <p>
      {{ $t('loginInHolaText2') }}
      <img src="@/assets/img/hola-scan-qr-icon.png" alt="">
    </p>
    <p>
      {{ $t('loginInHolaText3') }}
    </p>
  </div>
</template>
<script>
const LoginByQRCode = () => import('@/components/popups/QRLogin')
export default {
  name: 'LoginInHola',
  components: {
    LoginByQRCode
  }
}
</script>
<style lang="scss">
.login_step{
  .qr-popup__wrap{
    padding: 0;
  }
  p{
    img{
      max-width: 30px;
    }
  }
}
</style>
