<template>
  <div class="bridge">
    <div class="bridge-wrap">
      <div class="bridge-stepper">
        <stepper :count-steps="4" :active-step="activeStep" @change-step="activeStep = $event" />
      </div>
      <div class="bridge-content">
        <template v-if="activeStep === 1">
          <div class="bridge-title">{{ $t('loginToHola') }}</div>
          <login-in-hola @change-step="activeStep = $event" />
        </template>
        <template v-if="activeStep === 2">
          <div class="bridge-title">{{ $t('chooseMessenger') }}</div>
          <choose-provider @change-step="activeStep = $event" />
        </template>
        <template v-if="activeStep === 3">
          <template v-if="currentProvider === 'whatsapp'">
            <WhatsappFlow @change-step="activeStep = $event" />
          </template>
          <template v-if="currentProvider === 'telegram'">
            <TelegramFlow @change-step="activeStep = $event" />
          </template>
        </template>
        <template v-if="activeStep === 4">
          <div class="bridge-title">{{ $t('congratulations') }}</div>
          <SuccessScreen @change-step="activeStep = $event" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import ChooseProvider from './steps/ChooseProvider'
import LoginInHola from './steps/LoginInHola'
import Stepper from '@/components/features/Stepper'
import SuccessScreen from './steps/SuccessScreen'
import TelegramFlow from './steps/TelegramFlow'
import WhatsappFlow from './steps/WhatsappFlow'

import { mapMutations } from 'vuex'
export default {
  name: 'BridgeConnection',
  components: {
    ChooseProvider,
    LoginInHola,
    Stepper,
    SuccessScreen,
    TelegramFlow,
    WhatsappFlow
  },
  data () {
    return {
      activeStep: 1
    }
  },
  computed: {
    currentProvider () {
      return this.$route.query.provider
    }
  },
  created () {
    this.changeLoginByQRPopup({
      type: 'bridge',
      show: false
    })
  },
  beforeDestroy () {
    this.changeLoginByQRPopup({
      type: '',
      show: false
    })
  },
  methods: {
    ...mapMutations({
      changeLoginByQRPopup: 'auth/changeLoginByQRPopup'
    })
  }
}
</script>
<style lang="scss">
.text-center{
  text-align: center;
}
.color-green{
  color: $green;
}
.bridge{
  padding-bottom: 40px;
  @include respondTo(580px){
    padding-bottom: 20px;
  }
  &-wrap{
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
  }
  &-content{
    margin-top: 30px;
    p{
      font-size: 16px;
      line-height: 1.4;
      font-weight: 500;
      margin-bottom: 18px;
    }
  }
  &-stepper{
    margin-top: 40px;
    @include respondTo(600px){
      margin: 30px auto 0;
      max-width: 250px;
    }
  }
  &-title{
    font-size: 26px;
    font-weight: 600;
    margin: 15px 0;
    text-align: center;
  }
}
.connect-flow{
  p{
    font-size: 16px;
    line-height: 1.4;
  }
  &__text{
    max-width: 500px;
    margin: 20px auto 0;
  }
}
.mt-1{
  margin-top: 5px;
}
.mt-2{
  margin-top: 10px;
}
.mt-3{
  margin-top: 15px;
}
.mt-4{
  margin-top: 20px;
}
.mt-5{
  margin-top: 25px;
}
.mb-1{
  margin-bottom: 5px;
}
.mb-2{
  margin-bottom: 10px;
}
.mb-3{
  margin-bottom: 15px;
}
.mb-4{
  margin-bottom: 20px;
}
.mb-5{
  margin-bottom: 25px;
}
.d-flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}
.align-center{
  align-items: center;
}
</style>
