<template>
  <div class="connect-flow">
    <div class="connect-flow__text">
      <p class="text-center">
        {{ $t('successScreenText1') }}
      </p>
      <div class="connect-flow__verify">
        <a href="#" @click.prevent="$emit('change-step', 2)">
          {{ $t('verifyPhoneNumber') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  name: 'SuccessScreen',
  created () {
    // this.clearAuth()
  },
  methods: {
    ...mapMutations({
      clearAuth: 'auth/clearAuth'
    })
  }
}
</script>
<style lang="scss">
.connect-flow{
  &__verify{
    text-align: center;
    a{
      color: $green;
      text-decoration: underline;
      font-weight: 500;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
