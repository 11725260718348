<template>
  <div>
    <div class="bridge-title">{{ $t('bridgeToTelegram') }}</div>
    <BridgeTelegram @change-step="$emit('change-step', $event)" />
  </div>
</template>
<script>
import BridgeTelegram from '../providers/BridgeTelegram'
export default {
  name: 'TelegramFlow',
  components: {
    BridgeTelegram
  }
}
</script>
