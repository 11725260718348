<template>
  <div class="choose_provider">
    <template v-if="isPreparedMatrix && !isFirstSync && matrixCredentials">
      <div class="phone-form">
        <div class="phone-form__top">
          <PhoneInput
            style="margin-bottom: 0;"
            :value="phone"
            :errors="serverErrors.length && serverErrors"
            @on-update="handlePhoneInput($event)"
            @valid="isValidPhone = $event">
          </PhoneInput>
        </div>
        <div class="phone-form__bottom">
          <template v-if="matrixCredentials">
            <BaseButton style="margin: 0; width: 100%;" @change-event="handlePhoneForm" :progress="phoneLoading" class="right">{{ $t(user.phone ? 'changeAccountPhone' : 'addPhoneButton') }}</BaseButton>
          </template>
        </div>
      </div>
      <p v-html="$t('descriptionForBridgeAndVerifyPhone')"></p>
      <ul class="choose_provider-list" >
        <li>
          <div class="choose_provider-item">
            <div class="choose_provider__col">
            <span class="choose_provider__logo">
              <img src="@/assets/img/providers/telegram-ico.png" style="transform: scale(1.05)" alt="">
            </span>
              <span class="choose_provider__label">
              Telegram
            </span>
            </div>
            <a :class="{ 'connected': matrixCredentials.isBridgeTelegram, 'disabled': !!matrixCredentials.telegramPhone }" href="#"
               @click.prevent="handleNavigation('telegram')">
            <span class="choose_provider-item__logo">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.477 512.477" style="enable-background:new 0 0 512.477 512.477;" xml:space="preserve">
                  <path d="M500.364,292.311h-46.545V172.456c10.473,13.964,24.436,26.764,40.727,36.073c2.327,1.164,3.491,1.164,5.818,1.164    c3.491,0,8.145-2.327,10.473-5.818c3.491-5.818,1.164-12.8-4.655-16.291c-32.582-19.782-52.364-50.036-52.364-82.618    c0-6.982-4.655-11.636-11.636-11.636h-46.545c-6.982,0-11.636,4.655-11.636,11.636c0,58.182-57.018,104.727-128,104.727    s-128-46.545-128-104.727c0-6.982-4.655-11.636-11.636-11.636H69.818c-6.982,0-11.636,4.655-11.636,11.636v1.164    c0,32.582-18.618,62.836-52.364,82.618c-5.818,2.327-6.982,9.309-4.655,15.127c2.327,3.491,6.982,5.818,10.473,5.818    c2.327,0,3.491,0,5.818-1.164c16.291-10.473,30.255-22.109,40.727-36.073v118.691H11.636C5.818,291.147,0,295.802,0,302.784    s4.655,11.636,11.636,11.636l46.545,1.164v80.291H46.545c-6.982,0-11.636,4.655-11.636,11.636c0,6.982,4.655,11.636,11.636,11.636    h23.273h46.545h23.273c6.982,0,11.636-4.655,11.636-11.636c0-6.982-4.655-11.636-11.636-11.636H128v-46.545    c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v46.545H81.455v-91.927V116.602h24.436    c6.982,65.164,72.145,116.364,150.109,116.364s143.127-51.2,150.109-116.364h24.436v187.345v91.927h-23.273v-46.545    c0-6.982-4.655-11.636-11.636-11.636c-6.982,0-11.636,4.655-11.636,11.636v46.545h-11.636c-6.982,0-11.636,4.655-11.636,11.636    c0,6.982,4.655,11.636,11.636,11.636h23.273h46.545h23.273c6.982,0,11.636-4.655,11.636-11.636    c0-6.982-4.655-11.636-11.636-11.636h-11.636v-80.291h46.545c6.982,0,11.636-4.655,11.636-11.636S507.345,292.311,500.364,292.311    z"/>
                  <path d="M395.636,186.42c-6.982,0-11.636,4.655-11.636,11.636v94.255h-46.545v-47.709c0-6.982-4.655-11.636-11.636-11.636    s-11.636,4.655-11.636,11.636v47.709h-46.545v-36.073c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v36.073    h-46.545v-47.709c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v47.709H128v-94.255    c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v105.891c0,6.982,4.655,11.636,11.636,11.636h279.273    c6.982,0,11.636-5.818,11.636-11.636V198.056C407.273,191.075,402.618,186.42,395.636,186.42z"/>
              </svg>
            </span>
              <span class="choose_provider-item__label">
              {{ $t('labelConnectBridge') }}
              <svg v-show="matrixCredentials.isBridgeTelegram" style="width: 10px; height: 10px;" width="10" height="10" color="#00b764" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16 fa-2x"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
            </span>
            </a>
            <a :class="{ 'connected': matrixCredentials.isBridgeTelegram && !!matrixCredentials.telegramPhone, 'disabled': !matrixCredentials.isBridgeTelegram }" href="#"
               @click.prevent="handleVerifyPhone('telegram')">
            <span class="choose_provider-item__logo">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 502 502" style="enable-background:new 0 0 502 502;" xml:space="preserve">
                  <path d="M103.656,466.086c0,19.803,15,35.914,33.438,35.914h227.813c18.438,0,33.438-16.111,33.438-35.914V35.914    c0-19.803-15-35.914-33.438-35.914H137.094c-18.438,0-33.438,16.111-33.438,35.914V466.086z M123.656,78.047h254.688v302.37    H123.656V78.047z M123.656,35.914c0-8.775,6.028-15.914,13.438-15.914h227.813c7.409,0,13.438,7.139,13.438,15.914v22.133H123.656    V35.914z M378.344,466.086c0,8.775-6.028,15.914-13.438,15.914H137.094c-7.41,0-13.438-7.139-13.438-15.914v-65.669h254.688    V466.086z"/>
                  <path d="M251,468.83c15.517,0,28.14-12.623,28.14-28.14s-12.623-28.14-28.14-28.14c-15.516,0-28.14,12.623-28.14,28.14    S235.484,468.83,251,468.83z M251,432.551c4.488,0,8.14,3.651,8.14,8.14s-3.651,8.14-8.14,8.14s-8.14-3.651-8.14-8.14    S246.512,432.551,251,432.551z"/>
                  <path d="M260.329,30.061h-18.658c-5.523,0-10,4.478-10,10s4.477,10,10,10h18.658c5.522,0,10-4.478,10-10    S265.852,30.061,260.329,30.061z"/>
                  <path d="M152.527,165.85c-5.523,0-10,4.478-10,10v176.215c0,5.522,4.477,10,10,10s10-4.478,10-10V175.85    C162.527,170.327,158.05,165.85,152.527,165.85z"/>
                  <path d="M152.527,98.473c-5.523,0-10,4.478-10,10v26.951c0,5.522,4.477,10,10,10s10-4.478,10-10v-26.951    C162.527,102.95,158.05,98.473,152.527,98.473z"/>
              </svg>
            </span>
              <span class="choose_provider-item__label">
              {{ $t('labelVerifyPhone') }}
              <svg v-show="matrixCredentials.isBridgeTelegram && matrixCredentials.telegramPhone === user.phone" style="width: 10px; height: 10px;" width="10" height="10" color="#00b764" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16 fa-2x"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
              <svg v-show="matrixCredentials.isBridgeTelegram && !!matrixCredentials.telegramPhone && matrixCredentials.telegramPhone !== user.phone" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 378.303 378.303" style="width: 10px; height: 10px; enable-background:new 0 0 378.303 378.303;" xml:space="preserve"><polygon style="fill:#FF3501;" points="378.303,28.285 350.018,0 189.151,160.867 28.285,0 0,28.285 160.867,189.151 0,350.018   28.285,378.302 189.151,217.436 350.018,378.302 378.303,350.018 217.436,189.151 "/></svg>
            </span>
              <span v-show="isLoading && selectedProvider === 'telegram'" class="choose_provider-loader">
              <Spinner />
            </span>
            </a>
          </div>
        </li>
        <li>
          <div class="choose_provider-item">
            <div class="choose_provider__col">
            <span class="choose_provider__logo">
              <img src="@/assets/img/providers/whatsapp-ico.png" style="transform: scale(1.15)" alt="">
            </span>
              <span class="choose_provider__label">
              Whatsapp
            </span>
            </div>
            <a :class="{ 'connected': matrixCredentials.isBridgeWhatsapp, 'disabled': !!matrixCredentials.whatsappPhone }"
               href="#"
               @click.prevent="handleNavigation('whatsapp')">
            <span class="choose_provider-item__logo">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.477 512.477" style="enable-background:new 0 0 512.477 512.477;" xml:space="preserve">
                  <path d="M500.364,292.311h-46.545V172.456c10.473,13.964,24.436,26.764,40.727,36.073c2.327,1.164,3.491,1.164,5.818,1.164    c3.491,0,8.145-2.327,10.473-5.818c3.491-5.818,1.164-12.8-4.655-16.291c-32.582-19.782-52.364-50.036-52.364-82.618    c0-6.982-4.655-11.636-11.636-11.636h-46.545c-6.982,0-11.636,4.655-11.636,11.636c0,58.182-57.018,104.727-128,104.727    s-128-46.545-128-104.727c0-6.982-4.655-11.636-11.636-11.636H69.818c-6.982,0-11.636,4.655-11.636,11.636v1.164    c0,32.582-18.618,62.836-52.364,82.618c-5.818,2.327-6.982,9.309-4.655,15.127c2.327,3.491,6.982,5.818,10.473,5.818    c2.327,0,3.491,0,5.818-1.164c16.291-10.473,30.255-22.109,40.727-36.073v118.691H11.636C5.818,291.147,0,295.802,0,302.784    s4.655,11.636,11.636,11.636l46.545,1.164v80.291H46.545c-6.982,0-11.636,4.655-11.636,11.636c0,6.982,4.655,11.636,11.636,11.636    h23.273h46.545h23.273c6.982,0,11.636-4.655,11.636-11.636c0-6.982-4.655-11.636-11.636-11.636H128v-46.545    c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v46.545H81.455v-91.927V116.602h24.436    c6.982,65.164,72.145,116.364,150.109,116.364s143.127-51.2,150.109-116.364h24.436v187.345v91.927h-23.273v-46.545    c0-6.982-4.655-11.636-11.636-11.636c-6.982,0-11.636,4.655-11.636,11.636v46.545h-11.636c-6.982,0-11.636,4.655-11.636,11.636    c0,6.982,4.655,11.636,11.636,11.636h23.273h46.545h23.273c6.982,0,11.636-4.655,11.636-11.636    c0-6.982-4.655-11.636-11.636-11.636h-11.636v-80.291h46.545c6.982,0,11.636-4.655,11.636-11.636S507.345,292.311,500.364,292.311    z"/>
                  <path d="M395.636,186.42c-6.982,0-11.636,4.655-11.636,11.636v94.255h-46.545v-47.709c0-6.982-4.655-11.636-11.636-11.636    s-11.636,4.655-11.636,11.636v47.709h-46.545v-36.073c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v36.073    h-46.545v-47.709c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v47.709H128v-94.255    c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v105.891c0,6.982,4.655,11.636,11.636,11.636h279.273    c6.982,0,11.636-5.818,11.636-11.636V198.056C407.273,191.075,402.618,186.42,395.636,186.42z"/>
              </svg>
            </span>
              <span class="choose_provider-item__label">
              {{ $t('labelConnectBridge') }}
              <svg v-show="matrixCredentials.isBridgeWhatsapp" style="width: 10px; height: 10px;" width="10" height="10" color="#00b764" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16 fa-2x"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
            </span>
            </a>
            <a :class="{ 'connected': matrixCredentials.isBridgeWhatsapp && !!matrixCredentials.whatsappPhone, 'disabled': !matrixCredentials.isBridgeWhatsapp }"
               href="#"
               @click.prevent="handleVerifyPhone('whatsapp')">
            <span class="choose_provider-item__logo">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 502 502" style="enable-background:new 0 0 502 502;" xml:space="preserve">
                  <path d="M103.656,466.086c0,19.803,15,35.914,33.438,35.914h227.813c18.438,0,33.438-16.111,33.438-35.914V35.914    c0-19.803-15-35.914-33.438-35.914H137.094c-18.438,0-33.438,16.111-33.438,35.914V466.086z M123.656,78.047h254.688v302.37    H123.656V78.047z M123.656,35.914c0-8.775,6.028-15.914,13.438-15.914h227.813c7.409,0,13.438,7.139,13.438,15.914v22.133H123.656    V35.914z M378.344,466.086c0,8.775-6.028,15.914-13.438,15.914H137.094c-7.41,0-13.438-7.139-13.438-15.914v-65.669h254.688    V466.086z"/>
                  <path d="M251,468.83c15.517,0,28.14-12.623,28.14-28.14s-12.623-28.14-28.14-28.14c-15.516,0-28.14,12.623-28.14,28.14    S235.484,468.83,251,468.83z M251,432.551c4.488,0,8.14,3.651,8.14,8.14s-3.651,8.14-8.14,8.14s-8.14-3.651-8.14-8.14    S246.512,432.551,251,432.551z"/>
                  <path d="M260.329,30.061h-18.658c-5.523,0-10,4.478-10,10s4.477,10,10,10h18.658c5.522,0,10-4.478,10-10    S265.852,30.061,260.329,30.061z"/>
                  <path d="M152.527,165.85c-5.523,0-10,4.478-10,10v176.215c0,5.522,4.477,10,10,10s10-4.478,10-10V175.85    C162.527,170.327,158.05,165.85,152.527,165.85z"/>
                  <path d="M152.527,98.473c-5.523,0-10,4.478-10,10v26.951c0,5.522,4.477,10,10,10s10-4.478,10-10v-26.951    C162.527,102.95,158.05,98.473,152.527,98.473z"/>
              </svg>
            </span>
              <span class="choose_provider-item__label">
              {{ $t('labelVerifyPhone') }}
              <svg v-show="matrixCredentials.isBridgeWhatsapp && matrixCredentials.whatsappPhone === user.phone" style="width: 10px; height: 10px;" width="10" height="10" color="#00b764" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16 fa-2x"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>
              <svg v-show="matrixCredentials.isBridgeWhatsapp && !!matrixCredentials.whatsappPhone && matrixCredentials.whatsappPhone !== user.phone" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 378.303 378.303" style="width: 10px; height: 10px; enable-background:new 0 0 378.303 378.303;" xml:space="preserve"><polygon style="fill:#FF3501;" points="378.303,28.285 350.018,0 189.151,160.867 28.285,0 0,28.285 160.867,189.151 0,350.018   28.285,378.302 189.151,217.436 350.018,378.302 378.303,350.018 217.436,189.151 "/></svg>
            </span>
              <span v-show="isLoading && selectedProvider === 'whatsapp'" class="choose_provider-loader">
              <Spinner />
            </span>
            </a>
          </div>
        </li>
      </ul>
    </template>
    <div v-else class="d-flex justify-start choose_provider__loader">
      <Spinner />
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/atoms/base-button/index.vue'
import PhoneInput from '@/components/features/PhoneInput'
import Spinner from '@/components/atoms/Spinner'
import { mapActions, mapGetters } from 'vuex'
import * as matrixSDK from 'matrix-js-sdk'
import { chatNamesForBridge, msgTypes, selfMembershipTypes, eventTypes } from '@/helpers/variables'

const generatorPassword = require('generate-password')
export default {
  name: 'ChooseProvider',
  components: {
    BaseButton,
    PhoneInput,
    Spinner
  },
  data () {
    return {
      phone: '',
      serverErrors: [],
      isValidPhone: false,
      phoneLoading: false,
      isPreparedMatrix: false,
      isFirstSync: true,
      isLoading: true,
      selectedProvider: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/profile',
      matrixCredentials: 'matrix/matrixCredentials'
    })
  },
  watch: {
    user: {
      handler () {
        if (this.user) {
          this.phone = this.user.phone
        }
      },
      immediate: true,
      deep: true
    }
  },
  async mounted () {
    if (!window.matrixSDK) {
      await this.initMatrixSDK()
      await this.checkAvailableMatrixCredentials()
    } else {
      this.isPreparedMatrix = true
      this.isFirstSync = false
    }
  },
  methods: {
    ...mapActions({
      updatePhone: 'auth/updatePhone',
      getMatrixCredentials: 'matrix/getMatrixCredentials',
      saveMatrixCredentials: 'matrix/saveMatrixCredentials',
      updateMatrixCredentials: 'matrix/updateMatrixCredentials',
      getMyProfile: 'auth/getMyProfile'
    }),
    handlePhoneInput (phone) {
      this.phone = phone
      this.serverErrors = []
    },
    async handleNavigation (provider) {
      try {
        await this.$router.replace({ name: this.$route.name, query: { provider: provider } })
      } finally {
        this.$emit('change-step', 3)
      }
    },
    async handlePhoneForm () {
      this.errors = {}
      if (this.phoneLoading || !this.isValidPhone) return
      this.phoneLoading = true
      try {
        await this.updatePhone({ phone: this.phone })
        await this.handleDisconnectMessenger()
        await this.getMyProfile()
      } catch (error) {
        if (error.data) {
          const { errorType } = error.data
          errorType ? this.serverErrors.push(this.$t(`errorPhoneInvalid_${errorType}`)) : this.serverErrors.push(this.$t('errorPhoneInvalid'))
        }
      } finally {
        this.phoneLoading = false
      }
    },
    async handleDisconnectMessenger () {
      await this.sendLogoutMessageRoom(chatNamesForBridge.telegram)
      await this.sendLogoutMessageRoom(chatNamesForBridge.whatsapp)
      const payload = {
        is_bridge_whatsapp: false,
        whatsapp_phone: '',
        is_bridge_telegram: false,
        telegram_phone: ''
      }

      await this.updateMatrixCredentials(payload)
      await this.getMatrixCredentials()
    },
    async sendLogoutMessageRoom (chatName) {
      const rooms = window.matrixSDK.getRooms()
      const providerConnectingRoom = rooms.find(e => e.name === chatName && e.selfMembership === selfMembershipTypes.join)
      const content = {
        body: 'logout',
        msgtype: msgTypes.text
      }
      if (providerConnectingRoom) {
        await window.matrixSDK.sendEvent(providerConnectingRoom.roomId, eventTypes.message, content, '')
      }
    },
    async handleVerifyPhone (provider) {
      this.selectedProvider = provider
      this.isLoading = true
      try {
        if (provider === 'whatsapp') {
          const phone = this.findMatrixWAPhone()
          await this.updateMatrixCredentials({ whatsapp_phone: phone })
        }
        if (provider === 'telegram') {
          const phone = this.findMatrixTGPhone()
          await this.updateMatrixCredentials({ telegram_phone: phone })
        }
      } finally {
        this.isLoading = false
      }
    },
    findMatrixWAPhone () {
      const matrixRooms = window.matrixSDK.getRooms()
      const cuttingPart = 'Successfully logged in as '
      const matrixBridgeRoom = matrixRooms.find(e => e.name === chatNamesForBridge.whatsapp && e.selfMembership === selfMembershipTypes.join)
      const roomTimeline = matrixBridgeRoom.timeline
      const findMessageWithPhone = roomTimeline.find(e => e.event.content && e.event.content.msgtype === msgTypes.notice && e.event.content.body.includes(cuttingPart))
      const messageContent = findMessageWithPhone.event.content.body
      const getPhoneFromMessage = messageContent.split(cuttingPart)[1]
      const phone = getPhoneFromMessage.split(' (device')[0]
      return phone
    },
    findMatrixTGPhone () {
      const matrixRooms = window.matrixSDK.getRooms()
      const cuttingPart = 'Login code sent to '
      const matrixBridgeRoom = matrixRooms.find(e => e.name === chatNamesForBridge.telegram && e.selfMembership === selfMembershipTypes.join)
      const roomTimeline = matrixBridgeRoom.timeline
      const findMessageWithPhone = roomTimeline.find(e => e.event.content && e.event.content.msgtype === msgTypes.notice && e.event.content.body.includes(cuttingPart))
      const messageContent = findMessageWithPhone.event.content.body
      const getPhoneFromMessage = messageContent.split(cuttingPart)[1]
      const phone = getPhoneFromMessage.split('. Please send the code here.')[0]
      return phone
    },
    async registerUserInMatrix () {
      const { nickname } = this.user
      const password = generatorPassword.generate({
        length: 10,
        numbers: true
      })
      await window.matrixSDK.registerRequest({
        auth: {
          type: 'm.login.dummy'
        },
        inhibit_login: false,
        initial_device_display_name: 'app.element.io (Chrome, macOS)',
        password: password,
        type: 'm.login.dummy',
        username: nickname
      }).then(async () => {
        await this.saveMatrixCredentials({
          password: password,
          username: nickname
        })
        await this.loginInMatrix()
      })
    },
    async loginInMatrix () {
      const { username, password } = this.matrixCredentials
      const loginResponse = await window.matrixSDK.login('m.login.password', { user: username, password: password })
      const loginClient = await matrixSDK.createClient({
        baseUrl: process.env.VUE_APP_MATRIX_URL,
        accessToken: loginResponse.access_token,
        userId: loginResponse.user_id
      })

      window.matrixSDK = loginClient

      await window.matrixSDK.startClient()
    },
    async initMatrixSDK () {
      if (!window.matrixSDK) {
        let sdk = null
        const matrixUrl = process.env.VUE_APP_MATRIX_URL
        sdk = await matrixSDK.createClient({ baseUrl: matrixUrl })

        window.matrixSDK = sdk
      }
    },
    async checkAvailableMatrixCredentials () {
      await this.getMatrixCredentials()
      if (!this.matrixCredentials.username) {
        await this.registerUserInMatrix()
      } else {
        await this.loginInMatrix()
      }
      await this.getMatrixRooms()
      this.isPreparedMatrix = true
    },
    async getMatrixRooms () {
      await window.matrixSDK.once('sync', () => {
        if (this.isFirstSync) {
          this.isFirstSync = false
        }
      })
    }
  }
}
</script>
<style lang="scss">
.phone-form {
  margin-bottom: 10px;
  &__top{
    margin-bottom: 10px;
  }
  &__bottom{
    button{
      height: auto;
    }
  }
}
.choose_provider__loader{
  max-width: 420px;
  min-height: 50px;
  align-items: center;
}
.choose_provider{
  margin-top: 10px;
  &-loader{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 3px;
  }
  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    li{
      width: 100%;
      max-width: 235px;
      &:last-child{
        margin-right: 0;
        padding-left: 10px;
      }
      &:first-child{
        padding-right: 10px;
        border-right: 1px solid $bg-grey-mid;
        img{
          padding: 3px;
        }
      }
    }
  }
  &__col{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    border-radius: 3px;
    transition: background $trans;
    text-decoration: none;
    color: $darkTextColor;
    background: $white;
    margin-bottom: 5px;
    border: none;
    background: $main-bg-dark;
  }
  &__logo{
    margin-right: 5px;
  }
  &__label{
    font-size: 18px;
    font-weight: 600;
  }
  &-item{
    position: relative;
    &__label{
      font-size: 14px;
      font-weight: 500;
    }
    a{
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border: 1px solid $bg-grey-mid;
      padding: 5px 10px;
      border-radius: 3px;
      cursor: pointer;
      transition: background $trans;
      text-decoration: none;
      color: $darkTextColor;
      background: $white;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      &:hover{
        background: rgba($green, .2);
      }
      &.connected{
        background: $main-bg-dark;
        pointer-events: none;
      }
      &.disabled{
        background: $main-bg-dark;
        pointer-events: none;
      }
      strong{
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg{
          margin-top: 1px;
          margin-right: 3px;
        }
      }
    }
    &__logo{
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        width: 25px;
        height: 25px;
      }
    }
    img{
      max-width: 40px;
    }
    &__label{
      padding-left: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg{
        margin-left: 8px;
      }
    }
  }
}
</style>
